@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 768px) {
    /* Make scrollbar transparent */
    body::-webkit-scrollbar {
        background: transparent;
    }

    /* Remove scrollbar track */
    body::-webkit-scrollbar-track {
        background: none;
    }

    body {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}
